import { React, Page } from 'lib'
import GuidePage from '../_guidance'

function GuidancePage(props) {
  return (
    <Page whiteBackground>
      <GuidePage/>
    </Page>
  )
}

export default GuidancePage
